import request from '@/utils/request';
import consts from '@/utils/consts';



// 查询投注内容
export function apiGetBetContent(data) {
	return request({
		url: consts.businessPublic + '/plan/getBetContent',
		method: 'post',
		data: data
	})
}

// 查询平台方案详情
export function apiGetPlatformPlanDetail(data) {
	return request({
		url: consts.businessPublic + '/plan/getPlatformPlanDetail',
		method: 'post',
		data: {
			...data,
			noToast: true
		}
	})
}


// 头像上传
export function apiUploadFile(data) {
	return request({
		url: consts.businessPublic + '/upload/uploadFile',
		method: 'post',
		data: data
	})
}
