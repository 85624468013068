
import router from '../router'

/** 
 * [fnIsHasHistory 是否有历史地址]
 * @param  {[String]} tPath [跳转地址]
 * @return {[Null]}
 */
export function fnIsHasHistory(tPath) {
	if (router.options.history.state.back === null) {
		router.push({
			path: tPath
		})
	} else {
		router.back();
	}
}
/** 
 * [fnGoGeneralTxt 跳转通用文本页面]
 * @param  {[String]} tType [协议类型]
 * @return {[Null]}
 */
export function fnGoGeneralTxt(tType) {
	console.log(tType);
	router.push({
		name: 'GeneralTxt',
		query: {
			agreement: tType
		},
		params: {
			resetType: 0
		}
	})
}
