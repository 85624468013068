// 竞猜足球选项
export const jczqOptions = {
	hostWinSp: {
		name: '胜',
		lotteryResultEnum: 'WIN',
		lotterySubTypeEnum: 'JCZQ_SPF'
	},
	drawSp: {
		name: '平',
		lotteryResultEnum: 'DRAW',
		lotterySubTypeEnum: 'JCZQ_SPF'
	},
	guestWinSp: {
		name: '负',
		lotteryResultEnum: 'LOST',
		lotterySubTypeEnum: 'JCZQ_SPF'
	},
	hostRqWinSp: {
		name: '胜',
		lotteryResultEnum: 'WIN',
		lotterySubTypeEnum: 'JCZQ_RQSPF'
	},
	rqDrawSp: {
		name: '平',
		lotteryResultEnum: 'DRAW',
		lotterySubTypeEnum: 'JCZQ_RQSPF'
	},
	guestRqWinSp: {
		name: '负',
		lotteryResultEnum: 'LOST',
		lotterySubTypeEnum: 'JCZQ_RQSPF'
	},
	s00s00: {
		name: '0:0',
		lotteryResultEnum: 'BF_S00S00',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s00s01: {
		name: '0:1',
		lotteryResultEnum: 'BF_S00S01',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s00s02: {
		name: '0:2',
		lotteryResultEnum: 'BF_S00S02',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s00s03: {
		name: '0:3',
		lotteryResultEnum: 'BF_S00S03',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s00s04: {
		name: '0:4',
		lotteryResultEnum: 'BF_S00S04',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s00s05: {
		name: '0:5',
		lotteryResultEnum: 'BF_S00S05',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s01s00: {
		name: '1:0',
		lotteryResultEnum: 'BF_S01S00',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s01s01: {
		name: '1:1',
		lotteryResultEnum: 'BF_S01S01',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s01s02: {
		name: '1:2',
		lotteryResultEnum: 'BF_S01S02',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s01s03: {
		name: '1:3',
		lotteryResultEnum: 'BF_S01S03',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s01s04: {
		name: '1:4',
		lotteryResultEnum: 'BF_S01S04',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s01s05: {
		name: '1:5',
		lotteryResultEnum: 'BF_S01S05',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s02s00: {
		name: '2:0',
		lotteryResultEnum: 'BF_S02S00',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s02s01: {
		name: '2:1',
		lotteryResultEnum: 'BF_S02S01',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s02s02: {
		name: '2:2',
		lotteryResultEnum: 'BF_S02S02',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s02s03: {
		name: '2:3',
		lotteryResultEnum: 'BF_S02S03',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s02s04: {
		name: '2:4',
		lotteryResultEnum: 'BF_S02S04',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s02s05: {
		name: '2:5',
		lotteryResultEnum: 'BF_S02S05',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s03s00: {
		key: 's03s00',
		lotteryResultEnum: 'BF_S03S00',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s03s01: {
		name: '3:1',
		lotteryResultEnum: 'BF_S03S01',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s03s02: {
		name: '3:2',
		lotteryResultEnum: 'BF_S03S02',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s03s03: {
		name: '3:3',
		lotteryResultEnum: 'BF_S03S03',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s04s00: {
		name: '4:0',
		lotteryResultEnum: 'BF_S04S00',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s04s01: {
		name: '4:1',
		lotteryResultEnum: 'BF_S04S01',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s04s02: {
		name: '4:2',
		lotteryResultEnum: 'BF_S04S02',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s05s00: {
		name: '5:0',
		lotteryResultEnum: 'BF_S05S00',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s05s01: {
		name: '5:1',
		lotteryResultEnum: 'BF_S05S01',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s05s02: {
		name: '5:2',
		lotteryResultEnum: 'BF_S05S02',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s1sh: {
		name: '胜其他',
		lotteryResultEnum: 'BF_S1SH',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s1sa: {
		name: '负其他',
		lotteryResultEnum: 'BF_S1SA',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	s1sd: {
		name: '平其他',
		lotteryResultEnum: 'BF_S1SD',
		lotterySubTypeEnum: 'JCZQ_BF'
	},
	dd: {
		name: '平-平',
		lotteryResultEnum: 'BQCSPF_DD',
		lotterySubTypeEnum: 'JCZQ_BQC'
	},
	dh: {
		name: '平-胜',
		lotteryResultEnum: 'BQCSPF_DH',
		lotterySubTypeEnum: 'JCZQ_BQC'
	},
	da: {
		name: '平-负',
		lotteryResultEnum: 'BQCSPF_DA',
		lotterySubTypeEnum: 'JCZQ_BQC'
	},
	hd: {
		name: '胜-平',
		lotteryResultEnum: 'BQCSPF_HD',
		lotterySubTypeEnum: 'JCZQ_BQC'
	},
	hh: {
		name: '胜-胜',
		lotteryResultEnum: 'BQCSPF_HH',
		lotterySubTypeEnum: 'JCZQ_BQC'
	},
	ha: {
		name: '胜-负',
		lotteryResultEnum: 'BQCSPF_HA',
		lotterySubTypeEnum: 'JCZQ_BQC'
	},
	ah: {
		name: '负-胜',
		lotteryResultEnum: 'BQCSPF_AH',
		lotterySubTypeEnum: 'JCZQ_BQC'
	},
	ad: {
		name: '负-平',
		lotteryResultEnum: 'BQCSPF_AD',
		lotterySubTypeEnum: 'JCZQ_BQC'
	},
	aa: {
		name: '负-负',
		lotteryResultEnum: 'BQCSPF_AA',
		lotterySubTypeEnum: 'JCZQ_BQC'
	},
	s0: {
		name: '0',
		lotteryResultEnum: 'JQS_S0',
		lotterySubTypeEnum: 'JCZQ_JQS'
	},
	s1: {
		name: '1',
		lotteryResultEnum: 'JQS_S1',
		lotterySubTypeEnum: 'JCZQ_JQS'
	},
	s2: {
		name: '2',
		lotteryResultEnum: 'JQS_S2',
		lotterySubTypeEnum: 'JCZQ_JQS'
	},
	s3: {
		name: '3',
		lotteryResultEnum: 'JQS_S3',
		lotterySubTypeEnum: 'JCZQ_JQS'
	},
	s4: {
		name: '4',
		lotteryResultEnum: 'JQS_S4',
		lotterySubTypeEnum: 'JCZQ_JQS'
	},
	s5: {
		name: '5',
		lotteryResultEnum: 'JQS_S5',
		lotterySubTypeEnum: 'JCZQ_JQS'
	},
	s6: {
		name: '6',
		lotteryResultEnum: 'JQS_S6',
		lotterySubTypeEnum: 'JCZQ_JQS'
	},
	s7: {
		name: '7+',
		lotteryResultEnum: 'JQS_S7',
		lotterySubTypeEnum: 'JCZQ_JQS'
	}
};

// 竞猜篮球选项
export const jclqOptions = {
	aSfSp: {
		name: '客胜',
		lotteryResultEnum: 'JCLQ_SF_A',
		lotterySubTypeEnum: 'JCLQ_SF'
	},
	hSfSp: {
		name: '主胜',
		lotteryResultEnum: 'JCLQ_SF_H',
		lotterySubTypeEnum: 'JCLQ_SF'
	},
	aRfsfSp: {
		name: '客胜',
		lotteryResultEnum: 'JCLQ_RFSF_A',
		lotterySubTypeEnum: 'JCLQ_RFSF'
	},
	hRfzsSp: {
		name: '主胜',
		lotteryResultEnum: 'JCLQ_RFSF_H',
		lotterySubTypeEnum: 'JCLQ_RFSF'
	},
	hDxfSp: {
		name: '大于',
		betName: '大',
		lotteryResultEnum: 'JCLQ_DXF_H',
		lotterySubTypeEnum: 'JCLQ_DXF'
	},
	lDxfSp: {
		name: '小于',
		betName: '小',
		lotteryResultEnum: 'JCLQ_DXF_L',
		lotterySubTypeEnum: 'JCLQ_DXF'
	},
	l1SfcSp: {
		name: '客胜1~5',
		lotteryResultEnum: 'JCLQ_SFC_L1',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	l2SfcSp: {
		name: '客胜6~10',
		lotteryResultEnum: 'JCLQ_SFC_L2',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	l3SfcSp: {
		name: '客胜11~15',
		lotteryResultEnum: 'JCLQ_SFC_L3',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	l4SfcSp: {
		name: '客胜16~20',
		lotteryResultEnum: 'JCLQ_SFC_L4',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	l5SfcSp: {
		name: '客胜21~25',
		lotteryResultEnum: 'JCLQ_SFC_L5',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	l6SfcSp: {
		name: '客胜26+',
		lotteryResultEnum: 'JCLQ_SFC_L6',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	w1SfcSp: {
		name: '主胜1~5',
		lotteryResultEnum: 'JCLQ_SFC_W1',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	w2SfcSp: {
		name: '主胜6~10',
		lotteryResultEnum: 'JCLQ_SFC_W2',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	w3SfcSp: {
		name: '主胜11~15',
		lotteryResultEnum: 'JCLQ_SFC_W3',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	w4SfcSp: {
		name: '主胜16~20',
		lotteryResultEnum: 'JCLQ_SFC_W4',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	w5SfcSp: {
		name: '主胜21~25',
		lotteryResultEnum: 'JCLQ_SFC_W5',
		lotterySubTypeEnum: 'JCLQ_SFC'
	},
	w6SfcSp: {
		name: '主胜26+',
		lotteryResultEnum: 'JCLQ_SFC_W6',
		lotterySubTypeEnum: 'JCLQ_SFC'
	}
}

// 竞猜足球选项
export const bjdcOptions = {
	hostRqWinSp: {
		name: '胜',
		lotteryResultEnum: 'WIN',
		lotterySubTypeEnum: 'BJDC_SFGG'
	},
	guestRqWinSp: {
		name: '负',
		lotteryResultEnum: 'LOST',
		lotterySubTypeEnum: 'BJDC_SFGG'
	},
	hostWinSp: {
		name: '胜',
		lotteryResultEnum: 'WIN',
		lotterySubTypeEnum: 'BJDC_SPF'
	},
	drawSp: {
		name: '平',
		lotteryResultEnum: 'DRAW',
		lotterySubTypeEnum: 'BJDC_SPF'
	},
	guestWinSp: {
		name: '负',
		lotteryResultEnum: 'LOST',
		lotterySubTypeEnum: 'BJDC_SPF'
	},
	
	s00s00: {
		name: '0:0',
		lotteryResultEnum: 'BF_S00S00',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s00s01: {
		name: '0:1',
		lotteryResultEnum: 'BF_S00S01',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s00s02: {
		name: '0:2',
		lotteryResultEnum: 'BF_S00S02',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s00s03: {
		name: '0:3',
		lotteryResultEnum: 'BF_S00S03',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s00s04: {
		name: '0:4',
		lotteryResultEnum: 'BF_S00S04',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s01s00: {
		name: '1:0',
		lotteryResultEnum: 'BF_S01S00',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s01s01: {
		name: '1:1',
		lotteryResultEnum: 'BF_S01S01',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s01s02: {
		name: '1:2',
		lotteryResultEnum: 'BF_S01S02',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s01s03: {
		name: '1:3',
		lotteryResultEnum: 'BF_S01S03',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s01s04: {
		name: '1:4',
		lotteryResultEnum: 'BF_S01S04',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s02s00: {
		name: '2:0',
		lotteryResultEnum: 'BF_S02S00',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s02s01: {
		name: '2:1',
		lotteryResultEnum: 'BF_S02S01',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s02s02: {
		name: '2:2',
		lotteryResultEnum: 'BF_S02S02',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s02s03: {
		name: '2:3',
		lotteryResultEnum: 'BF_S02S03',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s02s04: {
		name: '2:4',
		lotteryResultEnum: 'BF_S02S04',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s03s00: {
		key: 's03s00',
		lotteryResultEnum: 'BF_S03S00',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s03s01: {
		name: '3:1',
		lotteryResultEnum: 'BF_S03S01',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s03s02: {
		name: '3:2',
		lotteryResultEnum: 'BF_S03S02',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s03s03: {
		name: '3:3',
		lotteryResultEnum: 'BF_S03S03',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s04s00: {
		name: '4:0',
		lotteryResultEnum: 'BF_S04S00',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s04s01: {
		name: '4:1',
		lotteryResultEnum: 'BF_S04S01',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s04s02: {
		name: '4:2',
		lotteryResultEnum: 'BF_S04S02',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s1sh: {
		name: '胜其他',
		lotteryResultEnum: 'BF_S1SH',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s1sa: {
		name: '负其他',
		lotteryResultEnum: 'BF_S1SA',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	s1sd: {
		name: '平其他',
		lotteryResultEnum: 'BF_S1SD',
		lotterySubTypeEnum: 'BJDC_BF'
	},
	dd: {
		name: '平-平',
		lotteryResultEnum: 'BQCSPF_DD',
		lotterySubTypeEnum: 'BJDC_BQC'
	},
	dh: {
		name: '平-胜',
		lotteryResultEnum: 'BQCSPF_DH',
		lotterySubTypeEnum: 'BJDC_BQC'
	},
	da: {
		name: '平-负',
		lotteryResultEnum: 'BQCSPF_DA',
		lotterySubTypeEnum: 'BJDC_BQC'
	},
	hd: {
		name: '胜-平',
		lotteryResultEnum: 'BQCSPF_HD',
		lotterySubTypeEnum: 'BJDC_BQC'
	},
	hh: {
		name: '胜-胜',
		lotteryResultEnum: 'BQCSPF_HH',
		lotterySubTypeEnum: 'BJDC_BQC'
	},
	ha: {
		name: '胜-负',
		lotteryResultEnum: 'BQCSPF_HA',
		lotterySubTypeEnum: 'BJDC_BQC'
	},
	ah: {
		name: '负-胜',
		lotteryResultEnum: 'BQCSPF_AH',
		lotterySubTypeEnum: 'BJDC_BQC'
	},
	ad: {
		name: '负-平',
		lotteryResultEnum: 'BQCSPF_AD',
		lotterySubTypeEnum: 'BJDC_BQC'
	},
	aa: {
		name: '负-负',
		lotteryResultEnum: 'BQCSPF_AA',
		lotterySubTypeEnum: 'BJDC_BQC'
	},
	s0: {
		name: '0',
		lotteryResultEnum: 'JQS_S0',
		lotterySubTypeEnum: 'BJDC_JQS'
	},
	s1: {
		name: '1',
		lotteryResultEnum: 'JQS_S1',
		lotterySubTypeEnum: 'BJDC_JQS'
	},
	s2: {
		name: '2',
		lotteryResultEnum: 'JQS_S2',
		lotterySubTypeEnum: 'BJDC_JQS'
	},
	s3: {
		name: '3',
		lotteryResultEnum: 'JQS_S3',
		lotterySubTypeEnum: 'BJDC_JQS'
	},
	s4: {
		name: '4',
		lotteryResultEnum: 'JQS_S4',
		lotterySubTypeEnum: 'BJDC_JQS'
	},
	s5: {
		name: '5',
		lotteryResultEnum: 'JQS_S5',
		lotterySubTypeEnum: 'BJDC_JQS'
	},
	s6: {
		name: '6',
		lotteryResultEnum: 'JQS_S6',
		lotterySubTypeEnum: 'BJDC_JQS'
	},
	s7: {
		name: '7+',
		lotteryResultEnum: 'JQS_S7',
		lotterySubTypeEnum: 'BJDC_JQS'
	}
};


/**
 * bjdcPlayWay 北京单场玩法
 * BJDC_SFGG(300, "胜负过关")
 * BJDC_SPF(301,"胜平负")
 * BJDC_JQS(302,"总进球")
 * BJDC_BQC(303,"半全场")
 * BJDC_BF(304,"单场比分")
 */
export const bjdcPlayWay = {
	sfgg: {
		code: "BJDC_SFGG",
		minSelect: 3, // 最少选择赛事
		maxSelect: 15, // 最大选择赛事
		minStrand: 3,
		maxStrand: 15
	},
	spf: {
		code: "BJDC_SPF",
		minSelect: 1,
		maxSelect: 15,
		minStrand: 1,
		maxStrand: 15
	},
	dcbf: {
		code: "BJDC_BF",
		minSelect: 1,
		maxSelect: 15,
		minStrand: 1,
		maxStrand: 3
	},
	jqs: {
		code: "BJDC_JQS",
		minSelect: 1,
		maxSelect: 15,
		minStrand: 1,
		maxStrand: 6
	},
	bqcspf: {
		code: "BJDC_BQC",
		minSelect: 1,
		maxSelect: 15,
		minStrand: 1,
		maxStrand: 6
	},
}

// 对应彩票类型包含的信息
export const olotteryType = {
	JCZQ: {
		bgcClass: "app_bgc033EC2"
	},
	JCLQ: {
		bgcClass: "app_bgcF21B8E"
	},
	BJDC: {
		bgcClass: "app_bgcAA1AE1"
	},
}
/* 
// 竞彩篮球-胜负
JCLQ_SF_A("A","主负"),
JCLQ_SF_H("H","主胜"),

// 竞彩篮球-让分胜负
JCLQ_RFSF_A("A","让分主负"),
JCLQ_RFSF_H("H","让分主胜"),

// 竞彩篮球-胜分差
JCLQ_SFC_L1("L1","客胜1-5分"),
JCLQ_SFC_L2("L2","客胜6-10分"),
JCLQ_SFC_L3("L3","客胜11-15分"),
JCLQ_SFC_L4("L4","客胜16-20分"),
JCLQ_SFC_L5("L5","客胜21-25分"),
JCLQ_SFC_L6("L6","客胜26+分"),
JCLQ_SFC_W1("W1","主胜1-5分"),
JCLQ_SFC_W2("W2","主胜6-10分"),
JCLQ_SFC_W3("W3","主胜11-15分"),
JCLQ_SFC_W4("W4","主胜16-20分"),
JCLQ_SFC_W5("W5","主胜21-25分"),
JCLQ_SFC_W6("W6","主胜26+分"),

// 竞彩篮球-大小分
JCLQ_DXF_H("H","大"),
JCLQ_DXF_L("L","小"),
*/
